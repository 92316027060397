import { logger } from 'logging-utils';
import { TicketProvider, TicketRef } from 'ox-common-types';
import { snapshot } from 'valtio';
import { setRowIssueTickets } from '../../issues/active-issues/store-actions/issue-store-actions';
import { IssuePages } from '../../issues/common/types/issues-types';
import { getStoreByPage } from '../../issues/common/utils/store-utils';
import { setRowIssueTicketsPipelines } from '../../issues/pipeline-issues/store-actions/pipeline-issues-store-actions';
import { TicketingTickets } from '../services/get-ticket-by-keys/get-tickets-by-keys';
import { createTicketStoreActions } from '../store-actions/create-ticket-store-actions';
import { ticketingStoreActions } from '../store-actions/ticketing-store-actions';
import { TicketingAction, TicketingVendor } from '../types/ticketing-types';

const openCreateTicketModal = (ticketingVendor: TicketingVendor) => {
  ticketingStoreActions.setShowCreateTicketModal(true);
  ticketingStoreActions.setSelectedAction(TicketingAction.CreateTicket);
  ticketingStoreActions.setChosenTicketingVendor(ticketingVendor);
};

const openCreateBulkTicketModal = (ticketingVendor: TicketingVendor) => {
  try {
    ticketingStoreActions.setShowCreateTicketModal(true);
    ticketingStoreActions.setSelectedAction(TicketingAction.BulkCreateTicket);
    ticketingStoreActions.setChosenTicketingVendor(ticketingVendor);
  } catch (error) {
    logger.error(error);
  }
};

const closeCreateTicketModal = () => {
  ticketingStoreActions.setShowCreateTicketModal(false);
  ticketingStoreActions.setSelectedAction(null);
  ticketingStoreActions.setChosenTicketingVendor(null);
  createTicketStoreActions.resetInputControlObject();
  createTicketStoreActions.resetInputSelectedControlObject();
  createTicketStoreActions.resetInputOptions();
};

const openLinkUnlinkTicketModal = (
  ticketingVendor: TicketingVendor,
  linkOrUnlink: 'link' | 'unlink',
) => {
  ticketingStoreActions.setSelectedAction(
    linkOrUnlink === 'link' ? TicketingAction.Link : TicketingAction.Unlink,
  );
  ticketingStoreActions.setChosenTicketingVendor(ticketingVendor);
  ticketingStoreActions.setShowLinkUnlinkTicketModal(true);
};

const closeLinkUnlinkTicketModal = () => {
  ticketingStoreActions.setShowLinkUnlinkTicketModal(false);
  ticketingStoreActions.setSelectedAction(null);
  ticketingStoreActions.setChosenTicketingVendor(null);
};

export const addTicketToIssue = (
  ticketRef: TicketRef,
  issuePage: IssuePages,
  issueId: string,
) => {
  const store = getStoreByPage(issuePage);
  store.selectedIssue?.tickets.push(ticketRef);
  const selectedIssueRow = store.issues.find(
    issue => issue.issueId === issueId,
  );
  selectedIssueRow?.tickets.push(ticketRef);
  if (
    selectedIssueRow?.issueDetailsHeaders &&
    !selectedIssueRow?.issueDetailsHeaders?.find(
      header => header.id === 'tickets',
    )
  ) {
    selectedIssueRow.issueDetailsHeaders.push({
      id: 'tickets',
      label: 'Tickets',
    });
  }
};

const removeTicketFromIssue = (
  ticketId: string,
  issuePage: IssuePages,
  issueId: string,
) => {
  const store = getStoreByPage(issuePage);
  const { selectedIssue } = snapshot(store);
  const ticketIndex = selectedIssue?.tickets.findIndex(
    ticket => ticket.key === ticketId,
  );
  if (ticketIndex === -1 || ticketIndex === undefined) {
    logger.error('Ticket not found in selected issue');
    return;
  }
  store.selectedIssue?.tickets.splice(ticketIndex, 1);
  const issueInIssues = store.issues.find(issue => issue.issueId === issueId);
  const ticketIndexInIssues = issueInIssues?.tickets.findIndex(
    ticket => ticket.key === ticketId,
  );
  if (ticketIndexInIssues === -1 || ticketIndexInIssues === undefined) {
    logger.error('Ticket not found in issues');
    return;
  }
  issueInIssues?.tickets.splice(ticketIndexInIssues, 1);
};

const setTicketingTicketsWithStatuses = (
  hoverIssueId: string,
  ticketsWithStatuses: TicketingTickets,
  issuePage: IssuePages,
) => {
  const store = getStoreByPage(issuePage);
  const { issues } = snapshot(store);
  const rowIssue = issues.find(issue => issue.issueId === hoverIssueId);
  const { tickets } = rowIssue || {};
  if (!rowIssue || !ticketsWithStatuses || !tickets) {
    return;
  }

  const ticketsWithStatus = tickets.map(rowIssueTicket => {
    const currentTicketWithStatus = ticketsWithStatuses.find(
      ticketWithStatus => ticketWithStatus.key === rowIssueTicket.key,
    );

    return {
      key: rowIssueTicket.key as string,
      ticketId: rowIssueTicket.key,
      provider: rowIssueTicket.provider as unknown as TicketProvider,
      status: currentTicketWithStatus
        ? currentTicketWithStatus.status
        : rowIssueTicket.status,
      link: rowIssueTicket.ticketLink || '',
    };
  });

  if (issuePage === IssuePages.PipelineIssues) {
    setRowIssueTicketsPipelines(hoverIssueId, ticketsWithStatus);
  } else if (issuePage === IssuePages.CurrentIssues) {
    setRowIssueTickets(hoverIssueId, ticketsWithStatus);
  }
};

export const ticketingActions = {
  openCreateTicketModal,
  openCreateBulkTicketModal,
  closeCreateTicketModal,
  openLinkUnlinkTicketModal,
  closeLinkUnlinkTicketModal,
  addTicketToIssue,
  removeTicketFromIssue,
  setTicketingTicketsWithStatuses,
};
